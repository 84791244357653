//src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.css';
//import './styles/buttons.css';
import './styles/App.css';
import 'firebaseui/dist/firebaseui.css';  // Import Firebase UI CSS
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);