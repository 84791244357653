// src/components/QuestionnaireModal.js
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { InfoIcon, X } from 'lucide-react';

const Tooltip = ({ content, children }) => (
  <TooltipPrimitive.Provider>
    <TooltipPrimitive.Root>
      <TooltipPrimitive.Trigger asChild>
        {children}
      </TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal>
        <TooltipPrimitive.Content
          className="bg-gray-800 text-white p-2 rounded shadow-lg text-sm max-w-xs"
          sideOffset={5}
        >
          {content}
          <TooltipPrimitive.Arrow className="fill-gray-800" />
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  </TooltipPrimitive.Provider>
);

const QuestionnaireModal = ({ initialData, onComplete, onClose, isOnboarding = false }) => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    skinType: '',
    skinSensitivity: '',
    skinConcerns: [],
    currentRoutine: '',
    feelingAfterCleansing: '',
    ...initialData
  });

  useEffect(() => {
    if (initialData) {
      setFormData(prevData => ({ ...prevData, ...initialData }));
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'skinConcerns') {
      setFormData(prevData => ({
        ...prevData,
        skinConcerns: checked
          ? [...prevData.skinConcerns, value]
          : prevData.skinConcerns.filter(item => item !== value)
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handleNext = () => setStep(prevStep => prevStep + 1);
  const handleBack = () => setStep(prevStep => prevStep - 1);

  const handleSubmit = (e) => {
    e.preventDefault();
    onComplete(formData);
  };

  const questionnaireSteps = [
    // Skin Type
    <motion.div key="skinType" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <h2 className="text-2xl font-bold mb-4">Your Skin Type</h2>
      <p className="mb-4">How would you describe your skin?</p>
      {['Dry', 'Balanced', 'Oily', 'Combination'].map(type => (
        <label key={type} className="block mb-2">
          <input
            type="radio"
            name="skinType"
            value={type}
            checked={formData.skinType === type}
            onChange={handleChange}
            className="mr-2"
          />
          {type}
          <Tooltip content={getSkinTypeDescription(type)}>
            <InfoIcon className="inline-block ml-1 w-4 h-4 text-gray-500" />
          </Tooltip>
        </label>
      ))}
    </motion.div>,

    // Skin Sensitivity
    <motion.div key="skinSensitivity" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <h2 className="text-2xl font-bold mb-4">Skin Sensitivity</h2>
      <p className="mb-4">How sensitive is your skin?</p>
      {['Not sensitive', 'Slightly sensitive', 'Very sensitive'].map(sensitivity => (
        <label key={sensitivity} className="block mb-2">
          <input
            type="radio"
            name="skinSensitivity"
            value={sensitivity}
            checked={formData.skinSensitivity === sensitivity}
            onChange={handleChange}
            className="mr-2"
          />
          {sensitivity}
        </label>
      ))}
    </motion.div>,

    // Skin Concerns
    <motion.div key="skinConcerns" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <h2 className="text-2xl font-bold mb-4">Your Skin Concerns</h2>
      <p className="mb-4">Select all that apply:</p>
      {['Breakouts', 'Scars', 'Brightening', 'Dark Spots', 'Dryness', 'Lines & Wrinkles', 'Saggy & Crepey Skin', 'Oiliness & Pores', 'Sensitive & Eczema-Prone'].map(concern => (
        <label key={concern} className="block mb-2">
          <input
            type="checkbox"
            name="skinConcerns"
            value={concern}
            checked={formData.skinConcerns.includes(concern)}
            onChange={handleChange}
            className="mr-2"
          />
          {concern}
          <Tooltip content={getSkinConcernDescription(concern)}>
            <InfoIcon className="inline-block ml-1 w-4 h-4 text-gray-500" />
          </Tooltip>
        </label>
      ))}
    </motion.div>,

    // Current Routine
    <motion.div key="currentRoutine" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <h2 className="text-2xl font-bold mb-4">Your Current Skincare Routine</h2>
      <p className="mb-4">How would you describe your current skincare routine?</p>
      {['Minimal', 'Basic', 'Extensive', 'Inconsistent'].map(routine => (
        <label key={routine} className="block mb-2">
          <input
            type="radio"
            name="currentRoutine"
            value={routine}
            checked={formData.currentRoutine === routine}
            onChange={handleChange}
            className="mr-2"
          />
          {routine}
          <Tooltip content={getRoutineDescription(routine)}>
            <InfoIcon className="inline-block ml-1 w-4 h-4 text-gray-500" />
          </Tooltip>
        </label>
      ))}
    </motion.div>,

    // Feeling After Cleansing
    <motion.div key="feelingAfterCleansing" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <h2 className="text-2xl font-bold mb-4">Feeling After Cleansing</h2>
      <p className="mb-4">How does your skin feel after cleansing?</p>
      {['Tight and dry', 'Comfortable', 'Still oily'].map(feeling => (
        <label key={feeling} className="block mb-2">
          <input
            type="radio"
            name="feelingAfterCleansing"
            value={feeling}
            checked={formData.feelingAfterCleansing === feeling}
            onChange={handleChange}
            className="mr-2"
          />
          {feeling}
        </label>
      ))}
    </motion.div>
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg max-w-md w-full relative">
        {!isOnboarding && (
          <button onClick={onClose} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        )}
        <AnimatePresence mode="wait">
          {questionnaireSteps[step]}
        </AnimatePresence>
        <div className="flex justify-between mt-4">
          {step > 0 && (
            <button onClick={handleBack} className="bg-gray-300 text-gray-800 p-2 rounded">Back</button>
          )}
          {step < questionnaireSteps.length - 1 ? (
            <button onClick={handleNext} className="bg-primary text-white p-2 rounded">Next</button>
          ) : (
            <button onClick={handleSubmit} className="bg-primary text-white p-2 rounded">Complete</button>
          )}
        </div>
      </div>
    </div>
  );
};


// Helper functions for tooltip content
const getSkinTypeDescription = (type) => {
  const descriptions = {
    'Dry': 'Skin that feels tight, flaky, or rough. It may be prone to irritation and sensitivity.',
    'Balanced': 'Also known as "normal" skin. Not too oily or too dry, with few imperfections.',
    'Oily': 'Skin that looks shiny and feels greasy. It may be prone to acne and enlarged pores.',
    'Combination': 'A mix of oily and dry areas. Typically, the T-zone (forehead, nose, chin) is oily while cheeks are dry.'
  };
  return descriptions[type] || 'No description available';
};

const getSkinConcernDescription = (concern) => {
  const descriptions = {
    'Breakouts': 'Acne, pimples, or blemishes that occur regularly.',
    'Scars': 'Marks left on the skin after injuries or severe acne.',
    'Brightening': 'Desire for a more radiant, even-toned complexion.',
    'Dark Spots': 'Hyperpigmentation or areas of darker skin.',
    'Dryness': 'Lack of moisture in the skin, leading to flakiness or tightness.',
    'Lines & Wrinkles': 'Fine lines or deeper creases in the skin, often associated with aging.',
    'Saggy & Crepey Skin': 'Loss of skin firmness and elasticity.',
    'Oiliness & Pores': 'Excess sebum production and visible or enlarged pores.',
    'Sensitive & Eczema-Prone': 'Skin that easily becomes irritated, red, or itchy.'
  };
  return descriptions[concern] || 'No description available';
};

const getRoutineDescription = (routine) => {
  const descriptions = {
    'Minimal': 'Basic cleansing and maybe moisturizing, with few products.',
    'Basic': 'Regular cleansing, toning, and moisturizing, possibly with a few targeted treatments.',
    'Extensive': 'A multi-step routine with various products for different skin concerns.',
    'Inconsistent': 'Skincare habits that vary day to day without a set routine.'
  };
  return descriptions[routine] || 'No description available';
};

export default QuestionnaireModal;