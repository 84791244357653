import React, { useState, useEffect } from 'react';
import { Heart, Trash2, ExternalLink, Star } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../../contexts/AuthContext'; 

const FavoriteProducts = () => {
  const [favoriteProducts, setFavoriteProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all');
  const [sort, setSort] = useState('name');
  const { token } = useAuth();

  // Function to decode product ID
  const decodeProductId = (encodedId) => {
    return decodeURIComponent(escape(atob(encodedId)));
  };

  const formatRating = (rating) => {
    if (typeof rating === 'number') {
      return rating.toFixed(1);
    }
    return 'N/A';
  };

  useEffect(() => {
    const fetchFavoriteProducts = async () => {
      try {
        // Fetch saved product IDs
        const savedProductsResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/user/get-saved-products`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!savedProductsResponse.ok) {
          throw new Error('Failed to fetch saved products');
        }

        const savedProductsData = await savedProductsResponse.json();
        const savedProductIds = savedProductsData.saved_products;

        if (savedProductIds.length === 0) {
          setFavoriteProducts([]);
          setLoading(false);
          return;
        }

        // Fetch product details
        const productsDetailsResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/user/get-products-details`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ product_ids: savedProductIds.map(decodeProductId) }),
        });

        if (!productsDetailsResponse.ok) {
          throw new Error('Failed to fetch product details');
        }

        const productsDetailsData = await productsDetailsResponse.json();
        setFavoriteProducts(productsDetailsData.products);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching favorite products:', err);
        setError('Failed to load favorite products. Please try again later.');
        setLoading(false);
      }
    };

    fetchFavoriteProducts();
  }, [token]);

  const handleRemove = async (productId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/save-product`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ product_id: productId }),
      });

      if (!response.ok) {
        throw new Error('Failed to remove product');
      }

      const data = await response.json();
      if (data.success) {
        setFavoriteProducts(favoriteProducts.filter(product => product.id !== productId));
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      console.error('Error removing product:', err);
      setError('Failed to remove product. Please try again.');
    }
  };

  const filteredProducts = filter === 'all' 
    ? favoriteProducts 
    : favoriteProducts.filter(product => product.product_type.toLowerCase() === filter);

  const sortedProducts = [...filteredProducts].sort((a, b) => {
    if (sort === 'name') return a.product_name.localeCompare(b.product_name);
    if (sort === 'price') return a.product_price - b.product_price;
    if (sort === 'rating') return b.product_rating - a.product_rating;
    return 0;
  });

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center p-4">{error}</div>;
  }

  return (
    <div className="max-w-6xl mx-auto">
      <h2 className="text-3xl font-bold text-primary mb-8">Your Favorite Products</h2>
      
      {favoriteProducts.length === 0 ? (
        <motion.div
          className="bg-white rounded-lg shadow-md p-8 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Heart size={48} className="text-primary mx-auto mb-4" />
          <p className="text-xl mb-6">You haven't saved any products yet.</p>
          <motion.button
            className="bg-primary text-white py-2 px-6 rounded-full hover:bg-primary-dark transition duration-300"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Explore Products
          </motion.button>
        </motion.div>
      ) : (
        <>
          <div className="flex flex-col md:flex-row justify-between items-center mb-6">
            <div className="flex flex-wrap gap-2 mb-4 md:mb-0">
              {['all', 'cleanser', 'moisturizer', 'serum', 'sunscreen'].map((type) => (
                <motion.button
                  key={type}
                  className={`px-4 py-2 rounded-full ${filter === type ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700'}`}
                  onClick={() => setFilter(type)}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </motion.button>
              ))}
            </div>
            <select 
              className="bg-white border border-gray-300 rounded-md px-4 py-2"
              value={sort}
              onChange={(e) => setSort(e.target.value)}
            >
              <option value="name">Sort by Name</option>
              <option value="price">Sort by Price</option>
              <option value="rating">Sort by Rating</option>
            </select>
          </div>

          <AnimatePresence>
            <motion.div 
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              {sortedProducts.map((product) => (
                <motion.div 
                  key={product.id} 
                  className="bg-white rounded-lg shadow-md overflow-hidden"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <img src={product.product_image_link} alt={product.product_name} className="w-full h-48 object-cover" />
                  <div className="p-4">
                    <h3 className="text-xl font-semibold mb-2">{product.product_name}</h3>
                    <p className="text-gray-600 mb-2">{product.brand}</p>
                    <div className="flex items-center mb-2">
                      <Star className="text-yellow-400 mr-1" size={16} />
                      <span>{formatRating(product.product_rating)} ({product.review_count || 0} reviews)</span>
                    </div>
                    <p className="text-lg font-bold mb-4">
                      {product.product_price ? `${product.product_price} ${product.price_currency}` : 'Price not available'}
                    </p>
                    <div className="flex justify-between">
                      <motion.button
                        className="bg-primary text-white py-2 px-4 rounded-full flex items-center"
                        onClick={() => window.open(product.product_link, '_blank')}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        View <ExternalLink size={16} className="ml-2" />
                      </motion.button>
                      <motion.button 
                        className="bg-red-500 text-white py-2 px-4 rounded-full flex items-center"
                        onClick={() => handleRemove(product.id)}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        Remove <Trash2 size={16} className="ml-2" />
                      </motion.button>
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </AnimatePresence>
        </>
      )}
    </div>
  );
};

export default FavoriteProducts;