import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Twitter, Instagram, Linkedin } from 'lucide-react';

function Footer() {
  return (
    <footer className="bg-white text-gray-700 py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-2xl font-semibold mb-4 text-primary">Skinmingle</h3>
            <p className="text-gray-600">Revolutionizing skincare with AI technology</p>
          </div>
          <div>
            <h4 className="text-xl font-semibold mb-4 text-primary">Quick Links</h4>
            <ul className="space-y-2">
              <li><Link to="/about" className="hover:text-primary transition duration-300">About Us</Link></li>
              <li><Link to="/technology" className="hover:text-primary transition duration-300">Our Technology</Link></li>
              <li><Link to="/blog" className="hover:text-primary transition duration-300">Blog</Link></li>
              <li><Link to="/contact" className="hover:text-primary transition duration-300">Contact</Link></li>
            </ul>
          </div>
          <div>
            <h4 className="text-xl font-semibold mb-4 text-primary">Legal</h4>
            <ul className="space-y-2">
              <li><Link to="/privacy" className="hover:text-primary transition duration-300">Privacy Policy</Link></li>
              <li><Link to="/terms" className="hover:text-primary transition duration-300">Terms of Service</Link></li>
            </ul>
          </div>
          <div>
          <h4 className="text-xl font-semibold mb-4 text-primary">Connect With Us</h4>
          <div className="flex space-x-4">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-primary transition duration-300">
              <Facebook size={24} />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-primary transition duration-300">
              <Twitter size={24} />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-primary transition duration-300">
              <Instagram size={24} />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-primary transition duration-300">
              <Linkedin size={24} />
            </a>
          </div>
        </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-300 text-center">
          <p className="text-gray-600">&copy; {new Date().getFullYear()} Skinmingle. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;