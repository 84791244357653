import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import ChatWindow from './ChatWindow';
import ProductSidebar from './ProductSidebar';
import { ShoppingBag, X, ArrowLeft, Home } from 'lucide-react';

function ChatbotPage() {
  const [messages, setMessages] = useState([]);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [productsAvailable, setProductsAvailable] = useState(false);
  const { token } = useAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchChatHistory();
  }, [token]);

  const fetchChatHistory = async () => {
    if (!token) return;

    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chatbot/chat-history`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch chat history');
      }

      const data = await response.json();
      setMessages(data.chat_history || []);
    } catch (error) {
      console.error('Error fetching chat history:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const sendMessage = async (inputMessage) => {
    if (!inputMessage.trim() || isLoading) return;

    setIsLoading(true);
    setProductsAvailable(false);
    const newMessage = { role: 'user', content: inputMessage };
    setMessages(prev => [...prev, newMessage]);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chatbot/stream`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          user_message: inputMessage,
        })
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const reader = response.body.getReader();
      let assistantMessage = '';
      let buffer = '';

      const processJSON = (jsonString) => {
        try {
          const data = JSON.parse(jsonString);
          switch (data.type) {
            case 'assistant_message':
              assistantMessage += data.content;
              setMessages(prev => {
                const updated = [...prev];
                const lastMessage = updated[updated.length - 1];
                if (lastMessage.role === 'assistant') {
                  lastMessage.content = assistantMessage;
                } else {
                  updated.push({ role: 'assistant', content: assistantMessage });
                }
                return updated;
              });
              break;
            case 'product_details':
              setProducts(data.content);
              setShowSidebar(true);
              setProductsAvailable(true);
              break;
            case 'end':
              setIsLoading(false);
              break;
          }
        } catch (parseError) {
          console.error('Error parsing JSON:', parseError, 'Raw data:', jsonString);
        }
      };

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = new TextDecoder().decode(value);
        buffer += chunk;

        let newlineIndex;
        while ((newlineIndex = buffer.indexOf('\n')) !== -1) {
          const line = buffer.slice(0, newlineIndex);
          buffer = buffer.slice(newlineIndex + 1);
          if (line.trim() !== '') {
            processJSON(line);
          }
        }
      }

      if (buffer.trim() !== '') {
        processJSON(buffer);
      }

    } catch (error) {
      console.error('Error in chat:', error);
      setIsLoading(false);
      setMessages(prev => [...prev, { role: 'assistant', content: 'Sorry, an error occurred. Please try again.' }]);
    } finally {
      setIsLoading(false);
    }
  };

  const clearChat = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chatbot/clear-chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to clear chat');
      }

      const data = await response.json();
      console.log(data.message);

      setMessages([]);
      setProducts([]);
      setShowSidebar(false);
      setProductsAvailable(false);
    } catch (error) {
      console.error('Error clearing chat:', error);
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100">
      {/* empty space for the header */}
      <div className="bg-white shadow-sm">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center py-4">
            <Link to="/" className="flex items-center">
              <img 
                src="/images/logo/black_logo.png" 
                alt="Skinmingle Logo" 
                className="h-8 w-auto mr-2"
              />
            </Link>
          </div>
        </div>
      </div>

      {/* Main content area */}
      <div className="flex-1 flex overflow-hidden relative">
        <div className="flex-1 flex flex-col">
          <ChatWindow 
            messages={messages}
            isLoading={isLoading}
            onSendMessage={sendMessage}
            onClearChat={clearChat}
            productsAvailable={productsAvailable}
            isMobile={isMobile}
          />
        </div>

        <AnimatePresence>
          {showSidebar && (
            <motion.div
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ type: 'spring', stiffness: 300, damping: 30 }}
              className={`bg-white z-50 ${isMobile ? 'fixed inset-0' : 'relative w-96'}`}
            >
              <ProductSidebar 
                products={products}
                onClose={() => setShowSidebar(false)}
              />
            </motion.div>
          )}
        </AnimatePresence>

        {productsAvailable && !showSidebar && (
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => setShowSidebar(true)}
            className="absolute bottom-20 right-4 bg-primary text-white p-3 rounded-full shadow-lg z-10"
          >
            <ShoppingBag size={24} />
          </motion.button>
        )}

        {showSidebar && isMobile && (
          <button
            onClick={() => setShowSidebar(false)}
            className="fixed top-20 right-4 bg-primary text-white p-2 rounded-full shadow-lg z-60"
          >
            <X size={24} />
          </button>
        )}
      </div>
    </div>
  );
}

export default ChatbotPage;