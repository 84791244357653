import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { motion } from 'framer-motion';
import { Sun, Moon, Menu, X } from 'lucide-react';

function Navigation() {
  const { currentUser, logout, theme, toggleTheme } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await logout();
      // Redirect to home page or login page after logout
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  const navItems = [
    { to: "/home", label: "Home" },
    { to: "/account", label: "MySkin" },
    { to: "/chatbot", label: "Chatbot" },
  ];

  return (
    <nav className={`sticky top-0 z-50 bg-white bg-opacity-50 backdrop-filter backdrop-blur-lg shadow-md transition-colors duration-300`}>
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center py-4">
          <Link to="/" className="flex items-center">
            <img 
              src="/images/logo/black_logo.png" 
              alt="Skinmingle Logo" 
              className="h-8 w-auto mr-2"
            />
          </Link>
          
          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-4">
            {currentUser && navItems.map((item) => (
              <Link 
                key={item.to} 
                to={item.to} 
                className={`px-3 py-2 rounded-full transition duration-300 ${
                  location.pathname === item.to 
                    ? 'bg-primary text-white' 
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
              >
                {item.label}
              </Link>
            ))}
            {currentUser ? (
              <button 
                onClick={handleLogout} 
                className="px-3 py-2 rounded-full text-gray-600 hover:bg-gray-100 transition duration-300"
              >
                Logout
              </button>
            ) : (
              <Link 
                to="/login" 
                className="px-3 py-2 rounded-full bg-primary text-white hover:bg-primary-dark transition duration-300"
              >
                Login
              </Link>
            )}
          </div>
          
          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button 
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-2 rounded-full hover:bg-gray-100 transition duration-300"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
        
        {/* Mobile Menu */}
        <motion.div 
          className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: isMenuOpen ? 1 : 0, y: isMenuOpen ? 0 : -20 }}
          transition={{ duration: 0.3 }}
        >
          {currentUser && navItems.map((item) => (
            <Link 
              key={item.to} 
              to={item.to} 
              className={`block px-3 py-2 rounded-md my-1 transition duration-300 ${
                location.pathname === item.to 
                  ? 'bg-primary text-white' 
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
              onClick={() => setIsMenuOpen(false)}
            >
              {item.label}
            </Link>
          ))}
          {currentUser ? (
            <button 
              onClick={() => {
                handleLogout();
                setIsMenuOpen(false);
              }} 
              className="block w-full text-left px-3 py-2 rounded-md my-1 text-gray-600 hover:bg-gray-100 transition duration-300"
            >
              Logout
            </button>
          ) : (
            <Link 
              to="/login" 
              className="block px-3 py-2 rounded-md my-1 bg-primary text-white hover:bg-primary-dark transition duration-300"
              onClick={() => setIsMenuOpen(false)}
            >
              Login
            </Link>
          )}
          <button 
            onClick={() => {
              toggleTheme();
              setIsMenuOpen(false);
            }} 
            className="flex items-center w-full px-3 py-2 rounded-md my-1 text-gray-600 hover:bg-gray-100 transition duration-300"
          >
          </button>
        </motion.div>
      </div>
    </nav>
  );
}

export default Navigation;