// src/firebase.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';  // Add this line

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "alert-shape-391318.firebaseapp.com",
  projectId: "alert-shape-391318",
  storageBucket: "alert-shape-391318.appspot.com",
  messagingSenderId: "530031570751",
  appId: "1:530031570751:web:d275d36ef3b1de569fad8a",
  measurementId: "G-5CV4BVMMZ7"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const analytics = firebase.analytics();  // Add this line
export default firebase;