import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Navigation from './components/Navigation/Navigation';
import Footer from './components/Footer/Footer';
import ChatbotLayout from './layouts/ChatbotLayout';  // New import for ChatbotLayout
// pages
import LandingPage from './pages/LandingPage/LandingPage';
import HomePage from './pages/HomePage/HomePage';
import AccountPage from './pages/AccountPage/AccountPage';
import LoginPage from './pages/LoginPage/LoginPage';
import ChatbotPage from './pages/ChatbotPage/ChatbotPage';
import AboutUs from './pages/AboutUs/AboutUs';
import OurTechnology from './pages/OurTechnology/OurTechnology';

import './styles/App.css';

// Import Firebase analytics
import { analytics } from './firebase';

function AppContent() {
  const location = useLocation();

  React.useEffect(() => {
    // Log page view event
    analytics.logEvent('page_view', {
      page_path: location.pathname,
      page_location: window.location.href,
      page_title: document.title
    });
  }, [location]);

  return (
    <div className="App">
      <Routes>
        {/* Chatbot route with custom layout */}
        <Route path="/chatbot" element={
          <PrivateRoute>
            <ChatbotLayout>
              <ChatbotPage />
            </ChatbotLayout>
          </PrivateRoute>
        } />

        {/* All other routes with standard layout */}
        <Route path="*" element={
          <>
            <Navigation />
            <main className="main-content">
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/home" element={
                  <PrivateRoute>
                    <HomePage />
                  </PrivateRoute>
                } />
                <Route path="/account" element={
                  <PrivateRoute>
                    <AccountPage />
                  </PrivateRoute>
                } />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/technology" element={<OurTechnology />} />
                <Route path="*" element={<Navigate to="/home" />} />
              </Routes>
            </main>
            <Footer />
          </>
        } />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;