import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [token, setToken] = useState(null);

  async function login(user) {
    try {
      const token = await user.getIdToken();
      setToken(token);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/manage_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Login failed');
      }
      
      const data = await response.json();
      setCurrentUser(user);
      setUserData(data.user);
      return { user: data.user, needsOnboarding: data.needsOnboarding };
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  }

  function logout() {
    return signOut(auth).then(() => {
      setCurrentUser(null);
      setUserData(null);
      setToken(null);
    });
  }

  async function completeOnboarding(onboardingData) {
    try {
      const token = await currentUser.getIdToken(true);
      setToken(token);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/complete_onboarding`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(onboardingData)
      });
      
      if (!response.ok) {
        throw new Error('Failed to complete onboarding');
      }
      
      const data = await response.json();
      setUserData(data.user);
      return data.user;
    } catch (error) {
      setError(error.message);
      throw error;
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        try {
          const token = await user.getIdToken();
          setToken(token);
          setProactiveTokenRefresh(user); // New function to set up proactive refresh
        } catch (error) {
          console.error('Error getting user token:', error);
        }
      } else {
        setToken(null);
      }
      setLoading(false);
    });
  
    return unsubscribe;
  }, []);
  
  function setProactiveTokenRefresh(user) {
    // Refresh token 5 minutes before it expires
    const REFRESH_TIME_BEFORE_EXPIRY = 5 * 60 * 1000; // 5 minutes in milliseconds
  
    let tokenExpirationTime = 0;
  
    user.getIdTokenResult().then((idTokenResult) => {
      tokenExpirationTime = idTokenResult.expirationTime;
    });
  
    setInterval(() => {
      const now = Date.now();
      if (now > tokenExpirationTime - REFRESH_TIME_BEFORE_EXPIRY) {
        user.getIdToken(true).then((newToken) => {
          // Update your stored token
          // You might want to trigger an update in your AuthContext here
        });
      }
    }, 60000); // Check every minute
  }
  const value = {
    currentUser,
    userData,
    token,
    login,
    logout,
    completeOnboarding,
    error
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}