import React from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { MessageCircle, Zap, Shield, Brain } from 'lucide-react';

const LandingPage = () => {
  const navigate = useNavigate();
  const { scrollYProgress } = useScroll();
  const opacityHero = useTransform(scrollYProgress, [0, 0.2], [1, 0]);
  const opacityFeatures = useTransform(scrollYProgress, [0.1, 0.3], [0, 1]);
  const opacityBenefits = useTransform(scrollYProgress, [0.3, 0.5], [0, 1]);
  const opacityWhy = useTransform(scrollYProgress, [0.5, 0.7], [0, 1]);
  const opacityCTA = useTransform(scrollYProgress, [0.7, 0.9], [0, 1]);

  const handleGetStarted = () => {
    navigate('/login');
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100 text-gray-800">
      <motion.header 
        style={{ opacity: opacityHero }}
        className="relative h-screen flex items-center justify-center overflow-hidden"
      >
        <div className="absolute inset-0 z-0">
          <img src="/images/radiant_dewy_skin_with_water_droplets.png" alt="Radiant skin" className="w-full h-full object-cover" />
          <div className="absolute inset-0 bg-black opacity-30"></div>
        </div>
        <div className="container mx-auto px-4 text-center relative z-10">
          <motion.h1
            className="text-5xl md:text-7xl font-bold mb-6 text-white drop-shadow-lg"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Your Personal AI Skincare Assistant
          </motion.h1>
          <motion.p
            className="text-xl md:text-2xl mb-8 text-white drop-shadow"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            24/7 personalized advice, routines, and product recommendations
          </motion.p>
          <motion.button
            onClick={handleGetStarted}
            className="bg-white text-primary hover:bg-primary hover:text-white font-bold py-3 px-8 rounded-full text-lg transition duration-300 shadow-lg"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Get Your AI Skincare Assistant Now
          </motion.button>
        </div>
      </motion.header>

      <main className="container mx-auto px-4 py-16">
        <motion.section className="mb-24" style={{ opacity: opacityFeatures }}>
          <h2 className="text-4xl font-bold mb-12 text-center">How Your AI Skincare Assistant Works</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              { icon: Brain, title: "Analyzes Your Skin", description: "Our AI learns your unique skin type, concerns, and environment" },
              { icon: MessageCircle, title: "24/7 Personalized Chat", description: "Get instant, tailored skincare advice anytime" },
              { icon: Zap, title: "Custom Routines", description: "Receive a personalized skincare routine that evolves with your skin" },
              { icon: Shield, title: "Product Recommendations", description: "Discover products perfectly matched to your skin's needs" }
            ].map((feature, index) => (
              <motion.div
                key={index}
                className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <feature.icon className="w-16 h-16 text-primary mb-6" />
                <h3 className="text-2xl font-semibold mb-4">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section className="mb-24" style={{ opacity: opacityBenefits }}>
          <div className="bg-white p-12 rounded-xl shadow-lg text-center relative overflow-hidden">
            <div className="absolute inset-0 opacity-25">
              <img src="/images/landingPage1.png" alt="Skincare products" className="w-full h-full object-cover" />
            </div>
            <div className="relative z-10">
              <h2 className="text-4xl font-bold mb-8">Experience Personalized Skincare</h2>
              <p className="text-xl mb-8">Join thousands of users who've transformed their skin with our AI assistant.</p>
              <ul className="text-left inline-block mb-8">
                <li className="flex items-center mb-4">
                  <Zap className="w-6 h-6 text-primary mr-4" />
                  <span>Instant, personalized skincare advice</span>
                </li>
                <li className="flex items-center mb-4">
                  <Shield className="w-6 h-6 text-primary mr-4" />
                  <span>Customized routines that evolve with your skin</span>
                </li>
                <li className="flex items-center">
                  <MessageCircle className="w-6 h-6 text-primary mr-4" />
                  <span>24/7 access to your AI skincare expert</span>
                </li>
              </ul>
              <motion.div
                className="inline-block"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <button
                  onClick={handleGetStarted}
                  className="bg-primary hover:bg-primary-dark text-white font-bold py-4 px-10 rounded-full text-xl transition duration-300 shadow-lg"
                >
                  Start Your Personalized Skincare Journey
                </button>
              </motion.div>
            </div>
          </div>
        </motion.section>

        <motion.section className="mb-24" style={{ opacity: opacityWhy }}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div>
              <img src="/images/landingPage2.png" alt="AI-powered skincare" className="rounded-xl shadow-lg" />
            </div>
            <div>
              <h2 className="text-4xl font-bold mb-8">Why Choose Our AI Skincare Assistant?</h2>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <Brain className="w-6 h-6 text-primary mr-4 mt-1" />
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Advanced AI Technology</h3>
                    <p className="text-gray-600">Our AI learns and adapts to your skin's changing needs over time.</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <MessageCircle className="w-6 h-6 text-primary mr-4 mt-1" />
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Always Available</h3>
                    <p className="text-gray-600">Get skincare advice whenever you need it, day or night.</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <Zap className="w-6 h-6 text-primary mr-4 mt-1" />
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Personalized Recommendations</h3>
                    <p className="text-gray-600">Discover products and routines tailored specifically to your skin.</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <Shield className="w-6 h-6 text-primary mr-4 mt-1" />
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Safe and Private</h3>
                    <p className="text-gray-600">Your skin data is securely protected and never shared.</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </motion.section>

        <motion.section className="text-center mb-24" style={{ opacity: opacityCTA }}>
          <h2 className="text-4xl font-bold mb-8">Ready for Your Personal Skincare Assistant?</h2>
          <motion.div
            className="inline-block"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <button
              onClick={handleGetStarted}
              className="bg-primary hover:bg-primary-dark text-white font-bold py-4 px-10 rounded-full text-xl transition duration-300 shadow-lg"
            >
              Get Started Now
            </button>
          </motion.div>
        </motion.section>
      </main>
    </div>
  );
};

export default LandingPage;