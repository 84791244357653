import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Select from 'react-select';
import { Country } from 'country-state-city';
import QuestionnaireModal from '../../components/QuestionnaireModal';

const OnboardingModal = ({ onComplete, isOpen }) => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    displayName: '',
    age: '',
    location: '',
    questionnaire: {},
    preferences: {
      newsletter: false,
      notifications: false
    }
  });

  const countryOptions = React.useMemo(() => 
    Country.getAllCountries().map(country => ({
      value: country.isoCode,
      label: country.name
    })),
    []
  );

  if (!isOpen) return null;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.startsWith('preferences.')) {
      const preference = name.split('.')[1];
      setFormData(prevData => ({
        ...prevData,
        preferences: { ...prevData.preferences, [preference]: checked }
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handleCountryChange = (selectedOption) => {
    setFormData(prevData => ({
      ...prevData,
      location: selectedOption.label
    }));
  };

  const handleNext = () => setStep(prevStep => prevStep + 1);
  const handleBack = () => setStep(prevStep => prevStep - 1);

  const handleQuestionnaireComplete = (questionnaireData) => {
    setFormData(prevData => ({
      ...prevData,
      questionnaire: questionnaireData
    }));
    handleNext();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const submissionData = {
      ...formData,
      age: formData.age ? parseInt(formData.age, 10) : null
    };
    onComplete(submissionData);
  };

  const steps = [
    // Welcome step
    <motion.div key="welcome" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="space-y-4">
      <h2 className="text-2xl font-bold mb-4">Welcome to Skinmingle!</h2>
      <p>We're excited to help you on your skincare journey. To provide you with the best personalized experience, we'd like to collect some information about you and your skin.</p>
      <p>This data will help us:</p>
      <ul className="list-disc list-inside space-y-2">
        <li>Customize product recommendations</li>
        <li>Provide tailored skincare advice</li>
        <li>Improve our services to better meet your needs</li>
      </ul>
      <p>Your privacy is important to us. We'll never share your personal information without your consent.</p>
      <button onClick={handleNext} className="w-full bg-primary text-white p-2 rounded hover:bg-primary-dark transition duration-300">Let's Get Started</button>
    </motion.div>,

    // Basic Info
    <motion.div key="basicInfo" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="space-y-4">
      <h2 className="text-2xl font-bold mb-4">Tell Us About Yourself</h2>
      <input
        type="text"
        name="displayName"
        value={formData.displayName}
        onChange={handleChange}
        placeholder="Display Name"
        className="w-full p-2 border rounded focus:ring-2 focus:ring-primary focus:border-transparent"
      />
      <input
        type="number"
        name="age"
        value={formData.age}
        onChange={handleChange}
        placeholder="Age"
        min="1"
        max="120"
        className="w-full p-2 border rounded focus:ring-2 focus:ring-primary focus:border-transparent"
      />
      <Select
        options={countryOptions}
        value={countryOptions.find(option => option.label === formData.location)}
        onChange={handleCountryChange}
        placeholder="Select your country"
        className="w-full"
      />
      <div className="flex justify-between">
        <button onClick={handleBack} className="bg-gray-300 text-gray-800 p-2 rounded hover:bg-gray-400 transition duration-300">Back</button>
        <button onClick={handleNext} className="bg-primary text-white p-2 rounded hover:bg-primary-dark transition duration-300">Next</button>
      </div>
    </motion.div>,

    // Questionnaire (using the refactored QuestionnaireModal)
    <QuestionnaireModal
      key="questionnaire"
      initialData={formData.questionnaire}
      onComplete={handleQuestionnaireComplete}
      isOnboarding={true}
    />,

    // Preferences
    <motion.div key="preferences" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="space-y-4">
      <h2 className="text-2xl font-bold mb-4">Your Preferences</h2>
      <label className="flex items-center space-x-2 p-2 border rounded hover:bg-gray-100 transition duration-300">
        <input
          type="checkbox"
          name="preferences.newsletter"
          checked={formData.preferences.newsletter}
          onChange={handleChange}
          className="form-checkbox text-primary focus:ring-primary"
        />
        <span>Subscribe to our newsletter for skincare tips and product updates</span>
      </label>
      <label className="flex items-center space-x-2 p-2 border rounded hover:bg-gray-100 transition duration-300">
        <input
          type="checkbox"
          name="preferences.notifications"
          checked={formData.preferences.notifications}
          onChange={handleChange}
          className="form-checkbox text-primary focus:ring-primary"
        />
        <span>Enable notifications for personalized skincare reminders</span>
      </label>
      <div className="flex justify-between mt-4">
        <button onClick={handleBack} className="bg-gray-300 text-gray-800 p-2 rounded hover:bg-gray-400 transition duration-300">Back</button>
        <button onClick={handleSubmit} className="bg-primary text-white p-2 rounded hover:bg-primary-dark transition duration-300">Complete Onboarding</button>
      </div>
    </motion.div>
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white p-8 rounded-lg max-w-md w-full max-h-[90vh] overflow-y-auto">
        <AnimatePresence mode="wait">
          {steps[step]}
        </AnimatePresence>
        <div className="mt-4 text-sm text-gray-500 text-center">
          Step {step + 1} of {steps.length}
        </div>
      </div>
    </div>
  );
};

export default OnboardingModal;